import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/ru';
import 'dayjs/locale/en';
import relativeTime from 'dayjs/plugin/relativeTime';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// import KeycloakPlugin from "@/plugins/keycloak";
import VueKeyCloak from '@dsb-norge/vue-keycloak-js'
import { VueKeycloakInstance } from "@dsb-norge/vue-keycloak-js/dist/types";

import * as echarts from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";


import './app.scss'

Vue.config.productionTip = false

dayjs.locale("ru");
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);
dayjs.extend(utc);

declare module 'vue/types/vue' {
  // 3. Declare augmentation for Vue
  interface Vue {
    $dayjs(date?: dayjs.ConfigType, option?: dayjs.OptionType, locale?: string): dayjs.Dayjs;
    $keycloak: VueKeycloakInstance
  }
}


const keycloakInitOptions = {
  url: process.env.VUE_APP_KEYCLOAK_URL,
  realm: 'demo',
  clientId: 'platform-client'
}

Vue.prototype.$dayjs = dayjs

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

echarts.use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
]);

// Vue.use(KeycloakPlugin, keycloakInitOptions)

// // eslint-disable-next-line @typescript-eslint/ban-ts-comment
// // @ts-ignore
// Vue.$keycloak.init({ onLoad: 'login-required' }).then((auth) => {
//   console.log("login-required")
// })

//

// Vue.$keycloak.init({ onLoad: 'login-required' }).then((auth) => {
//   if (!auth) {
//     window.location.reload();
//   } else {
//     new Vue({
//       router,
//       store,
//       render: h => h(App)
//     }).$mount('#app')
//
//     window.onfocus = () => {
//       updateToken()
//     }
//   }
// })

Vue.use(VueKeyCloak, {
  config: keycloakInitOptions,
  init: {
    // Use 'login-required' to always require authentication
    // If using 'login-required', there is no need for the router guards in router.js
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html"
  },
  onReady: (keycloak) => {
    // console.log(`I wonder what Keycloak returns: ${keycloak}`)
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  },
})

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')
